
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import CampoSenha from '@/core/components/UI/CampoSenha.vue';
import ServicoApp from '@/servicos/ServicoApp';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IDTOUsuarioMeusDados } from '@/models/DTO/Usuarios/IDTOUsuarioMeusDados';
import storeApp from '@/store/storeApp';
import GerenciadorAutenticacao from '@/core/gerenciadores/GerenciadorAutenticacao';

export default defineComponent({
  name: 'MeusDados',
  components: {
    ComunicacaoApi,
    CampoEmail,
    CampoTelefone,
    CampoSenha,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarMensagemSucesso,
    } = useAppBase();
    const servicoApp = new ServicoApp();
    const gerenciadorAutenticacao = new GerenciadorAutenticacao();
    const state = reactive({
      meusDados: {} as IDTOUsuarioMeusDados,
    });

    function preencherMeusDados() {
      appBase.carregando = true;
      state.meusDados = {} as IDTOUsuarioMeusDados;
      state.meusDados.codigo = storeApp.state.usuarioAutenticado.codigo;
      state.meusDados.nome = storeApp.state.usuarioAutenticado.nome;
      state.meusDados.email = storeApp.state.usuarioAutenticado.email;
      state.meusDados.senha = storeApp.state.usuarioAutenticado.senha;
      state.meusDados.telefone = storeApp.state.usuarioAutenticado.telefone;
      appBase.carregando = false;
    }

    onBeforeMount(async () => {
      preencherMeusDados();
      const retornoAutenticacao = await servicoApp.atualizarToken();
      if (retornoAutenticacao.autenticado) {
        gerenciadorAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
      }
    });
    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos atualizando as suas informações.');

      appBase.resposta = await servicoApp.atualizarDadosUsuarioAutenticado(state.meusDados);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        apresentarMensagemSucesso('Dados atualizados com sucesso!');
        storeApp.mutations.atualizarUsuarioAutenticadoMeusDados(state.meusDados);
      }
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
